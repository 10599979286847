import React from 'react';

interface Props {
  className?: string;
  buttonText: string;
  onClick?(e: any): void;
  href?: string | undefined;
}
const PrimaryButton = ({ className = '', href, buttonText, onClick }: Props) => {
  function maybeClick(e: any) {
    if (!onClick) {
      return false;
    }

    onClick(e);
  }

  return (
    <a
      href={href}
      onClick={(e: any) => maybeClick(e)}
      className={[
        'bg-primary btn-primary table px-8 py-3 rounded-full text-white font-medium ',
        className,
      ].join(' ')}
    >
      {buttonText}
    </a>
  );
};

export default PrimaryButton;
