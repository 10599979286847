import React from 'react';

const MicrophoneIcon = ({
  className = '',
  fill = '#767676',
  onClick
}: {
  className?: string;
  fill?: string;
  onClick?: any;
}) => {
  return (
    <svg
      width="11"
      height="16"
      viewBox="0 0 11 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        fill={fill}
        d="M5.45455 11C7.09773 11 8.42975 9.65688 8.42975 8V3C8.42975 1.34312 7.09773 0 5.45455 0C3.81136 0 2.47934 1.34312 2.47934 3V8C2.47934 9.65688 3.81136 11 5.45455 11ZM10.4132 6H9.91736C9.64339 6 9.42149 6.22375 9.42149 6.5V8C9.42149 10.3375 7.42283 12.2131 5.05816 11.9806C2.99721 11.7778 1.4876 9.90969 1.4876 7.82188V6.5C1.4876 6.22375 1.2657 6 0.991736 6H0.495868C0.221901 6 0 6.22375 0 6.5V7.755C0 10.5563 1.98254 13.0534 4.71074 13.4328V14.5C4.71074 14.5 4.71074 14.7072 4.71074 14.9835V15.5C4.71074 15.7763 4.71074 16 4.71074 16H6.19835C6.19835 16 6.19835 15.7763 6.19835 15.5V15C6.19835 14.7238 6.19835 14.5 6.19835 14.5V13.4447C8.85465 13.0772 10.9091 10.7781 10.9091 8V6.5C10.9091 6.22375 10.6872 6 10.4132 6Z"
      />
    </svg>
  );
};

export default MicrophoneIcon;
