import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/global.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import BaseLayout from './components/Layouts/BaseLayout';
import Homepage from './pages/Homepage';
import FourOuFour from './pages/errors/_404';
import ThankYou from './pages/ThankYou';
import ExpiratedLink from './pages/ExpiratedLink';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<BaseLayout />}>
          <Route index element={<Homepage />} />
          <Route path={'/thank-you'} element={<ThankYou />} />
          <Route path={'/expired-link'} element={<ExpiratedLink />} />
          <Route path="*" element={<Homepage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
);
