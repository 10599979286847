import React from 'react';

interface Props {
  className?: string;
  fill?: string;
}

const CloseIcon = ({ className = '', fill = '#001E45' }: Props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.5"
        d="M0.318191 0.318191C-0.106064 0.742446 -0.106063 1.4303 0.318191 1.85455L6.46364 8L0.318191 14.1454C-0.106064 14.5697 -0.106064 15.2576 0.318191 15.6818C0.742446 16.1061 1.4303 16.1061 1.85455 15.6818L8 9.53636L14.1454 15.6818C14.5697 16.1061 15.2576 16.1061 15.6818 15.6818C16.1061 15.2576 16.1061 14.5697 15.6818 14.1454L9.53636 8L15.6818 1.85455C16.1061 1.4303 16.1061 0.742446 15.6818 0.318191C15.2576 -0.106064 14.5697 -0.106063 14.1454 0.318191L8 6.46364L1.85455 0.318191C1.4303 -0.106064 0.742446 -0.106064 0.318191 0.318191Z"
        fill="#001E45"
      />
    </svg>
  );
};

export default CloseIcon;
