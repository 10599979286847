import React, { useEffect, useState } from 'react';
import StarsRatingItem from './StarsRatingItem';

const StarsRating = ({
  className = '',
  onChange,
  componentName,
}: {
  className?: string;
  componentName: string;
  onChange(componentName: string, value: any): void;
}) => {
  const [selected, setSelected] = useState(0);
  const [hovering, setHovering] = useState(0);

  function maybeSetIsHovering(index: number) {
    if (window.innerWidth < 769) {
      return false;
    }

    setHovering(index);
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!selected) {
        return false;
      }

      onChange(componentName, selected);
    }, 0);

    return () => {
      clearTimeout(timeout);
    };
  }, [selected]);

  return (
    <div className={['flex flex-row gap-4', className].join(' ')}>
      {Array(5)
        .fill(null)
        .map(function (v, i) {
          return (
            <StarsRatingItem
              key={`${componentName}_star_rating_${i + 1}`}
              index={i + 1}
              onClick={() => setSelected(i + 1)}
              onMouseEnter={() => maybeSetIsHovering(i + 1)}
              onMouseLeave={() => setHovering(0)}
              isSelected={selected >= i + 1}
              isHovering={hovering >= i + 1}
            />
          );
        })}
    </div>
  );
};

export default StarsRating;
