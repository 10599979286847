import React, { useState } from 'react';
import TextArea from '../Inputs/TextArea';
import StarsRating from '../Inputs/StarsRating';
import PrimaryButton from '../Buttons/PrimaryButton';
import { useNavigate, useLocation } from 'react-router-dom';

interface Props {
  sendMessage(text: string): void;
}

const FeedbackForm = ({ sendMessage }: Props) => {
  const STEP_ENTRY = "STEP_ENTRY";
  const STEP_EXIT = "STEP_EXIT";

  const urlLocation = useLocation().pathname;

  const [rating, setRating] = useState(-1);
  const [step, setStep] = useState(STEP_ENTRY);

  const navigate = useNavigate();

  const sendSocketMessage = (componentName: string, value: any) => {
    sendMessage(
      JSON.stringify({
        question: componentName,
        value,
        url: urlLocation
      })
    );
  }

  const onChange = (componentName: string, value: any) => {
    // TODO :: Send to WS server and process the ack respose
    console.log('Sending', { componentName, value });

    if (componentName === "starsRating") {
      setRating(value);
    }

    sendSocketMessage(componentName, value);
  };

  const onSubmit = (e: MouseEvent) => {
    if (step === STEP_ENTRY) {
      sendSocketMessage("_submit1", new Date().toISOString());
    } else if (step === STEP_EXIT) {
      sendSocketMessage("_submit2", new Date().toISOString());
    }

    if (step === STEP_ENTRY) {
      setStep(STEP_EXIT);
    } else {
      navigate('/thank-you', {
        replace: true,
        state: { rating }
      });
    }

    e.preventDefault();
  };

  return (
    <div>
      <h1 className={'text-base mt-6 text-center font-medium'}>
        { step === STEP_EXIT && rating > 4 && (
	  "Sme radi, že ste spokojní s našimi službami. Budeme ešte radšej, keď nám zanecháte aj textové hodnotenie"
        )}
        { step === STEP_EXIT && rating === 3 && (
	  "Mrzí nás, že ste nemali optimálnu skúsenosť na našom eshope. Zanechajte nám prosím textové hodnotenie, nech vieme, kde sa máme zlepšiť"
	)}
        { step === STEP_EXIT && rating < 3 && (
	  "Zdá sa, že máme priestor na zlepšenie.<br>Zanechajte nám prosím textový odkaz, spravíme všetko pre to, aby sme Vašu skúsenosť do budúcna vylepšili."
        )}
      </h1>

      <form>
        { step !== STEP_ENTRY &&
          <TextArea
            label={'Komentár'}
            placeholder={'Napíšte nám Váš komentár'}
            className={'w-full mt-12 text-sm'}
            onChange={onChange}
            componentName={'feedbackMessage'}
          />
        }

        { step !== STEP_ENTRY && rating < 4 && (
          <h1 className={'text-base mt-6 text-center font-medium'}>
            Mrzí nás, že ste neboli spokojní.
          </h1>
        )}

        { step === STEP_ENTRY &&
          <React.Fragment>
            <h3 className={'mt-6'}>Jak hodnotíte své zkušenosti s oddělením služeb zákazníkům?</h3>
            <StarsRating className={'mt-4'} onChange={onChange} componentName={'starsRating'} />

          </React.Fragment>
        }

        <PrimaryButton buttonText={'Odeslat'} onClick={onSubmit} className={'m-auto !mt-20'} />
      </form>
    </div>
  );
};

export default FeedbackForm;
