import React from 'react';
import EmptyStarIcon from '../Icons/EmptyStarIcon';
import ClickedStarIcon from '../Icons/ClickedStartIcon';
import HoverStarIcon from '../Icons/HoverStarIcon';

interface Props {
  index: number;
  isHovering?: boolean;
  isSelected?: boolean;
  onClick(): void;
  onMouseEnter(): void;
  onMouseLeave(): void;
}

// TODO :: HOVER STARS
const StarsRatingItem = ({
  index,
  isHovering = false,
  isSelected = false,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: Props) => {
  if (isSelected) {
    return (
      <div onClick={(e) => onClick()} className={'cursor-pointer'}>
        <ClickedStarIcon />
      </div>
    );
  }

  return (
    <div
      onClick={(e) => onClick()}
      className={'cursor-pointer'}
      onMouseEnter={() => onMouseEnter()}
      onMouseLeave={() => onMouseLeave()}
    >
      {isHovering ? <HoverStarIcon /> : <EmptyStarIcon />}
    </div>
  );
};

export default StarsRatingItem;
