import React from 'react';

const CheckIcon = ({ className = '', fill = '#34A866' }: { className?: string; fill?: string }) => {
  return (
    <svg
      width="12"
      height="9"
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M1 5.50019L4.09524 8L11 1" stroke={fill} strokeWidth="1.15" />
    </svg>
  );
};

export default CheckIcon;
