import React from 'react';

interface Props {
  children: any;
  className?: string;
}

const Container = ({ children, className = '' }: Props) => {
  return <div className={['container m-auto', className].join(' ')}>{children}</div>;
};

export default Container;
