import React, { useEffect, useRef, useState } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

import styles from './TextArea.module.css';
import CheckIcon from '../Icons/CheckIcon';
import CloseIcon from '../Icons/CloseIcon';
import MicrophoneIcon from '../Icons/MicrophoneIcon';

interface Props {
  name?: string;
  className?: string;
  value?: string | number | readonly string[] | undefined;
  label?: string;
  placeholder?: string;
  voiceToSpeech?: boolean;
  onChange(componentName: string, value: any): void;
  componentName: string;
}

const TextArea = ({
  name,
  value = '',
  label = '',
  placeholder,
  voiceToSpeech = true,
  className = '',
  onChange,
  componentName,
}: Props) => {
  const [text, setText] = useState<any>(undefined);
  const textAreaRef = React.useRef<HTMLTextAreaElement>(null);
  const componentDidMount = useRef(false);

  const {
    finalTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  const [recording, setRecording] = useState(false);

  function onTextChange(e: any) {
    const textArea = textAreaRef.current;

    if (!textArea) {
      return false;
    }

    const value = e?.target?.value || '';

    setText(value);

    textArea.style.height = '';
    textArea.style.height = Math.min(textArea.scrollHeight, 600) + 'px';

    // Prevent little scrol bar
    if (textArea.scrollHeight < 600) {
      textArea.style.overflowY = 'hidden';
    } else {
      textArea.style.overflowY = 'scroll';
    }
  }

  useEffect(() => {
    setText((text:string) => {
      if (typeof text === 'undefined') {
        return finalTranscript;
      } else if (text.endsWith(" ")) {
        return text + finalTranscript;
      } else {
        return text + ' ' + finalTranscript;
      }
    })
  }, [finalTranscript]);

  useEffect(() => {
    if (typeof text === 'undefined') {
      return;
    }

    if (!componentDidMount.current) {
      componentDidMount.current = true;
      return;
    }

    const timeout = setTimeout(() => {
      onChange(componentName, text);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [text]);

  function clearText() {
    setText('');
  }

  const voiceRecording = () => {
      SpeechRecognition.startListening({
        language: 'cs'
      });
  }

  return (
    <div className={['relative', className].join(' ')}>
      {label && (
        <span
          className={[
            'bg-white px-2 py-1 absolute -top-3.5 left-3 text-sm',
            text?.length > 0 && 'text-green flex flex-row gap-1 items-center',
          ].join(' ')}
        >
          {label}
          {text?.length > 0 && <CheckIcon className={'ml-1'} />}
        </span>
      )}

      <textarea
        ref={textAreaRef}
        id={'textarea'}
        value={text}
        placeholder={placeholder}
        className={[styles.textarea].join(' ')}
        onChange={onTextChange}
      ></textarea>

      {text?.length > 0 && (
        <div
          onClick={() => clearText()}
          className={'absolute top-4 right-4 cursor-pointer px-2 py-2'}
        >
          <CloseIcon />
        </div>
      )}

      {voiceToSpeech && browserSupportsSpeechRecognition && (
        <div className={'absolute bottom-6 left-6'}>
          <MicrophoneIcon onClick={voiceRecording} />
        </div>
      )}
    </div>
  );
};

export default TextArea;
