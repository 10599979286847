import React from 'react';
import SecondaryButton from '../components/Buttons/SecondaryButton';
import Container from '../components/Layouts/Container';
import PrimaryButton from '../components/Buttons/PrimaryButton';
import { Helmet } from 'react-helmet';
import ExpiratedLinkIllustration from '../components/Illustrations/ExpiratedLinkIllustration';

const ExpiratedLink = () => {
  const hours = process.env.REACT_APP_TIME_DELTA_EXPIRATION;

  return (
    <div>
      <Helmet>
        <title>iStores.sk - Ups, tento odkaz už expiroval</title>
      </Helmet>
      <Container>
        <div className={'m-auto table mt-6'}>
          <ExpiratedLinkIllustration />
        </div>

        <h1 className={'mt-4 font-medium text-blue text-center'}>Ups, tento odkaz už expiroval</h1>
        <p className={'text-center table m-auto text-xs mt-3 text-blue'}>
          Vaša spätná väzba nás zaujíma, ale bohužiaľ na odkaz ste klikli po viac ako {hours} hodinách.
        </p>
      </Container>
    </div>
  );
};

export default ExpiratedLink;
